import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import MessageIcon from '../../fsa-style/img/svgs/MessageIcon.svg';
import RoutingOrchestrationIcon from '../../../fsa-style/img/svgs/RoutingOrchestrationIcon.svg';
import RuleDefinitionIcon from '../../../fsa-style/img/svgs/RuleDefinitionIcon.svg';
import RolesTableIcon from '../../../fsa-style/img/svgs/rolesTable.svg';
import PermissionsTableIcon from '../../../fsa-style/img/svgs/permissionsTable.svg';
import UsersTableIcon from '../../../fsa-style/img/svgs/usersTable.svg';
// import RuleTriggerIcon from '../../fsa-style/img/svgs/RuleTriggerIcon.svg';
// import WorkgrupIcon from '../../fsa-style/img/svgs/WorkgrupIcon.svg';
// import WorkgroupUserXwalkIcon from '../../fsa-style/img/svgs/WorkgroupUserXwalkIcon.svg';
import RoutingStepsIcon from '../../../fsa-style/img/svgs/RoutingStepsIcon.svg';
import SystemConfigurationIcon from '../../../fsa-style/img/svgs/SystemConfigurationIcon.svg';
import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader';
import './AdminSearchPage.scss';
import UserContext from '../../../components/UserContext/UserContext';
import SystemFolder from '../../../fsa-style/img/svgs/SystemFolder.svg';
import UserPreference from '../../../fsa-style/img/svgs/UserPreference.svg';
import UserGuide from '../../../fsa-style/img/svgs/User_guide2.svg';
import DeleteObject from '../../../fsa-style/img/svgs/deleteObject.svg';

const AdministratorPanel = () => {
  const [message, setMessage] = useState();
  const initialCardData = [
    // {
    //   title: 'Routing Orchestration',
    //   buttonName: 'View More ',
    //   pageUrl: '/orchestration',
    //   icon: RoutingOrchestrationIcon,
    //   id: 1,
    // },
    // {
    //   title: 'Routing Steps',
    //   buttonName: 'View More ',
    //   pageUrl: '/routingsteps',
    //   icon: RoutingStepsIcon,
    //   id: 2,
    // },
    // {
    //   title: 'System Configuration',
    //   buttonName: 'View More ',
    //   pageUrl: '/systemconfiguration',
    //   icon: SystemConfigurationIcon,
    //   id: 3,
    // },
    // {
    //   title: 'Rule Definition',
    //   buttonName: 'View More ',
    //   pageUrl: '/ruledefinition',
    //   icon: RuleDefinitionIcon,
    //   id: 4,
    // },
    {
      title: 'Users',
      buttonName: 'View More ',
      pageUrl: '/userstable',
      icon: UsersTableIcon,
      id: 5,
    },
    {
      title: 'Roles',
      buttonName: 'View More ',
      pageUrl: '/rolestable',
      icon: RolesTableIcon,
      id: 6,
    },
    {
      title: 'Permissions',
      buttonName: 'View More',
      pageUrl: '/permissionstable',
      icon: PermissionsTableIcon,
      id: 7,
    },

    // {
    //   title: 'Rule Definition',
    //   buttonName: 'View More ',
    //   pageUrl: '/ruledefinition',
    //   icon: RuleDefinitionIcon,
    // },
    // {
    //   title: 'Rule Trigger',
    //   buttonName: 'View More ',
    //   pageUrl: '/ruletrigger',
    //   icon: RuleTriggerIcon,
    // },
    // {
    //   title: 'Workgroup',
    //   buttonName: 'View More ',
    //   pageUrl: '/page2',
    //   icon: WorkgrupIcon,
    // },
    // {
    //   title: 'Workgroup User XWalk',
    //   buttonName: 'View More ',
    //   pageUrl: '/workgroupuser',
    //   icon: WorkgroupUserXwalkIcon,
    // },

    {
      title: 'System Folder',
      buttonName: 'View More ',
      pageUrl: '/system-folder',
      icon: SystemFolder,
      id: 3,
    },
    {
      title: 'User Preference',
      buttonName: 'View More ',
      pageUrl: '/user-preference',
      icon: UserPreference,
      id: 4,
    },
    {
      title: 'User Guide',
      buttonName: 'View More',
      pageUrl: '/userguide',
      icon: UserGuide,
      id: 8,
    },
    {
      title: 'Object Delete Log',
      buttonName: 'View More',
      pageUrl: '/deleteobjectlog',
      icon: DeleteObject,
      id: 9,
    },
  ];
  const history = useHistory();

  const [cardData, setCardData] = useState(initialCardData);
  const [filteredCardData, setFilteredCardData] = useState(initialCardData);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const { userPermissions } = React.useContext(UserContext);

  const filterCards = (keyword) => {
    const filteredCards = cardData.filter((card) => card.title.toLowerCase().includes(keyword.toLowerCase()) || card.buttonName.toLowerCase().includes(keyword.toLowerCase()));
    if (filteredCards.length === 0 && keyword.length !== 0) {
      setMessage('Name not found');
    } else setMessage('');
    setFilteredCardData(filteredCards);
  };

  const handleCardButton = (pageUrl) => {
    history.push(pageUrl);
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    if (value.length > 2) onSearch(value);
    if (value.length === 0) onSearch(value);
    setSearchKeyword(value.trimStart());
  };

  const onSearch = async (value) => {
    setLoading(true);
    const filteredCards = cardData.filter((card) => card.title.toLowerCase().includes(value.toLowerCase()));
    if (filteredCards.length === 0 && value.length !== 0) {
      setMessage('Name not found');
    } else setMessage('');
    setFilteredCardData(filteredCards);
    setLoading(false);
  };
  useEffect(() => {
    let newData;
    if (JSON.parse(sessionStorage.getItem('user_information')).rolePermissions?.includes('Object_Delete_Log') === false) {
      newData = initialCardData?.filter((cards) => {
        return cards.title !== 'Object Delete Log';
      });
      setFilteredCardData(newData);
    }
  }, []);

  const clearSearch = async () => {
    setLoading(true);
    setSearchKeyword('');
    setMessage('');
    setFilteredCardData(initialCardData);
    setLoading(false);
  };
  const renderCard = (card) => {
    return (
      <>
        <section className="col-3 card-section" key={`${card.id}_admin_card`}>
          <div className="card admin-panel-card mt-2 mb-3">
            <div className="card-circle mx-auto white">
              <div className=" dashboardIconOuterWhite">
                <div className="dashboardIconOuter">
                  <svg xmlns="http://www.w3.org/2000/svg" width="113" height="113" viewBox="0 0 113 113" fill="none">
                    <circle cx="56.5" cy="56.5" r="56.5" fill="white" />
                  </svg>
                  <img className={card.icon == UserGuide ? 'userGuideIcon' : card.icon == DeleteObject ? 'ObjectDeleteLogIcon' : 'card-icon'} src={card.icon} alt="icon"></img>
                </div>
              </div>
            </div>
            <div className="card-body text-center">
              <h4 className="card-title">{card.title}</h4>

              <div className="horizontalBar">
                <span>
                  {' '}
                  <hr className="horizontalBarContent"></hr>
                </span>
              </div>

              <h6 className="card-button-name">
                <CustomButton className="admin-panel-button" parentWarpper={'w-100'} title={card.buttonName} onClick={() => handleCardButton(card.pageUrl)} />
              </h6>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <>
      <main className="main-admin-panel">
        {isLoading && <GlobalLoader />}

        <div className="main-card-content flex-column">
          <div className="row col-md-12 main-card-content mt-5">
            {filteredCardData.map((card, index) => (
              <React.Fragment key={`${index}_admin_panels_cruds`}>
                {/* {JSON.parse(sessionStorage.getItem('user_information')).rolesName?.includes('Application Administrator') === true && card.title === 'Users' && renderCard(card)} */}
                {((JSON.parse(sessionStorage.getItem('user_information')).rolesName?.includes('Application Administrator') === true && card.title === 'Users') ||
                  JSON.parse(sessionStorage.getItem('user_information')).rolesName?.includes('Admin') === true ||
                  (JSON.parse(sessionStorage.getItem('user_information')).rolePermissions?.includes('Object_Delete_Log') === true && card.title === 'Object Delete Log')) &&
                  renderCard(card)}
              </React.Fragment>
            ))}
          </div>
        </div>
      </main>
    </>
  );
};

export default AdministratorPanel;
