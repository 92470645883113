import axios from 'axios';

const config = {
  headers: {
    'Content-Type': 'application/json',
    userName: 'FilePlanApp',
    usdaeauthid: '28200403099030045911',
    Authorization: sessionStorage.getItem('userJwt'),
  },
};
// const headers = {
//   'usdaeauthid': '28200403099030045911',
//   'userName': 'Everett Koechner'
// }

export async function search(searchObject, page, take, userInfo = undefined) {
  let searchResponse = [];
  try {
    const url = '/doc_and_metadata_submission_api/submission/search?page=' + page / take + '&limit=' + take;
    await axios
      .post(url, searchObject, {
        // TODO remove hardcoded values and get from userInfo
        headers: {
          officeId: 1,
          agencyCode: 'FA',
          Authorization: sessionStorage.getItem('userJwt'),
        },
      })
      .then((response) => {
        searchResponse = response;
      });
    return searchResponse;
  } catch (ex) {
    console.log('Error in metadataSubmissionService.js search()', ex.message);
    return ex;
  }
}

//this should be initParams(userAgency)
export async function initParams(agency) {
  let url = '/metadata_schema_api/metadata-schema/objectType?agency=FSA';
  const response = await axios.get(url, config);
  return response;
}

export async function initStateList(agency) {
  let url = '/external_service_api/location/states?standard=FSA';
  const response = await axios.get(url, config);
  return response;
}

export async function initCountyList(state, setCountyList) {
  console.log('user state: ' + state);
  let url = '/external_service_api/location/counties?stateCode=' + state + '&standard=FSA';
  try {
    const response = await axios.get(url, config);
    return response;
  } catch (ex) {
    return { data: [] };
  }
}

export async function objectSpecificList(doctype) {
  let url = '/metadata_schema_api/metadata-schema/all?objectTypeName=' + encodeURIComponent(doctype);
  const response = await axios.get(url, config);
  return response;
}

export async function allMetaDropList() {
  let url = '/metadata_schema_api/metadata-schema/searchableFields';
  const response = await axios.get(url, config);
  return response;
}

export async function indexSheetMetaDropList() {
  let url = '/metadata_schema_api/metadata-schema/searchableFields?nodePrefix=scan_sheet';
  const response = await axios.get(url, config);
  return response;
}

export async function initMetaDropList(doctype) {
  let url = '/metadata_schema_api/metadata-schema/searchableFields?objectType=' + doctype;
  const response = await axios.get(url, config);
  return response;
}

export async function getDocDetail(objectId) {
  let url = '/doc_and_metadata_submission_api/submission/' + objectId + '?allVersions=false';
  const response = await axios.get(url, config).catch((error) => {
    if (error.response) console.log('error response received from Submission when attempting to get the document details.', error.toJSON());
    else console.log('Submission API is not reachable when calling getDocDetail method.', error.request);
    return error;
  });
  return response;
}

export async function getCustomers(ccids) {
  let searchResponse = [];
  try {
    const url = '/external_service_api/scims/getCustomers?ccids=' + ccids;
    console.log('metadataSubmissionService.js - getCustomers: Calling ' + url);
    await axios.get(url, config).then((response) => {
      searchResponse = response;
    });
  } catch (ex) {
    console.log('Error in metadataSubmissionService.js getCustomers()', ex.message);
  }

  return searchResponse;
}

export async function deleteObjectSearch(Ids) {
  let searchResponse = [];
  const userInfo = JSON.parse(sessionStorage.getItem('user_information'));
  try {
    const url = '/doc_and_metadata_submission_api/submission/bulk/' + Ids.toString() + '?deleteAllVersions=false&hardDelete=false';
    console.log('metadataSubmissionService.js - delete Object: Calling ' + url);
    let header = {
      headers: {
        agencyCode: '',
        applicationid: userInfo !== null && Object.keys(userInfo).length > 0 ? userInfo.usdaagencycode?.[0] : '',
        department: userInfo !== null && Object.keys(userInfo).length > 0 ? userInfo.department : '',
        eauthId: userInfo !== null && Object.keys(userInfo).length > 0 ? userInfo.usdaeauthid : '',
        officeId: userInfo !== null && Object.keys(userInfo).length > 0 ? userInfo.officeId : '',
        organizationCode: userInfo !== null && Object.keys(userInfo).length > 0 ? userInfo.organizationCode : '',
        wsstatictoken: '2c72c2e6-4faf-470f-9b4b-346f8b52de9e',
        'Content-Type': 'application/json',
        userName: userInfo !== null && Object.keys(userInfo).length > 0 ? userInfo.userName : '',
        usdaeauthid: userInfo !== null && Object.keys(userInfo).length > 0 ? userInfo.usdaeauthid : '',
        Authorization: sessionStorage.getItem('userToken'),
      },
    };
    await axios.delete(url, header).then((response) => {
      searchResponse = response;
    });
  } catch (ex) {
    console.log('Error in metadataSubmissionService.js delete object ()', ex.message);
    searchResponse.error = ex.response.data;
  }

  return searchResponse;
}
