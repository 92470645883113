import React, { useState, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import UpdateObjectFileCodeModal from '../modals/UpdateObjectFileCodeModal/UpdateObjectFileCodeModal';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import DetailsView from '../modals/DetailsView/DetailsView';
import * as moment from 'moment-timezone';
import CustomPager from '../CustomPager/CustomPager';
import UpdateObjectHoldModal from '../modals/UpdateObjectHoldModal/UpdateObjectHoldModal';
import UpdateObjectTransferModal from '../modals/UpdateObjectTransferModal/UpdateObjectTransferModal';
import styles from './DrmsSearch.module.scss';
import '../../GlobalStyle.scss';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import searchImg from '../../fsa-style/img/searchImg.svg';
import CustomButton from '../../newcomponents/CustomButton/CustomButton';
export default function DRMSGrid({
  isMetadataLoading,
  cellClicked,
  objectID,
  errorMessage,
  drmsSearch,
  skip,
  setSkip,
  take,
  sort,
  setSort,
  isLoading,
  setTake,
  searchMetadata,
  records,
  setRecords,
  columnsToShow,
  handleColumnChange,
  handleRowClick,
  setFilter,
  SELECTED_FIELD,
  objectMetadata,
  setObjectMetadata,
  doc,
  detailOpen,
  setDetailOpen,
  onDocumentLoadSuccess,
  pageNumber,
  changePage,
  numPages,
  docName,
  docType,
  loading,
  scimsCustomers,
  metadataDisplayList,
  setMetadataDisplayList,
  rowsChecked,
  objFileCodePopupOpen,
  setObjFileCodePopupOpen,
  filter,
  searchParamsObjArray,
  userInfo,
  userId,
  agencyCode,
  docDetailResponse,
  documentTitle,
  sections,
  viewMoreFunc,
  detailsObjList,
  selectObjectTypeToView,
  objectTypeToView,
  selectedRecord,
  onDeleteObject,
  userPermissions,
  setBulkPopupOpen,
  holdResponse,
  refreshData,
  clearAll,
  fetchDocDetails,
  deleteRecordEntry,
  dataUpdated,
  setDataUpdated,
  fullTextFlag,
  keywordSearch,
  setDropdownFilterChanged,
  fetchDropDownFilterCell,
  setDropdownFilterCell,
  dropdownFilterCell,
  dropdownFilterChanged,
  clickedSearchRow,
}) {
  let _export;

  const [loadingFileCodes, setLoadingFileCodes] = useState(true);
  const [displayUpdateObjectTransferModal, setDisplayUpdateObjectTransferModal] = useState(false);
  const [isHoldModalVisible, setIsHoldModalVisible] = useState(false);
  const [drmsPage, setDrmsPage] = useState({ drmsSkip: 0, drmsTake: 25 });

  const closeHoldModal = () => {
    setIsHoldModalVisible(false);
    setBulkPopupOpen(false);
  };

  const openHoldModal = () => {
    setIsHoldModalVisible(true);
    setBulkPopupOpen(true);
  };

  const { drmsSkip, drmsTake } = drmsPage;

  let colData = JSON.parse(sessionStorage.getItem('systemFolderOptions'));

  const TableRef = useRef(null);

  const pageChange = (e) => {
    setSkip(e.page.skip);
    setTake(e.page.take);
    if (filter.length > 0) {
      let filterParamsArray = [...searchParamsObjArray, ...filter];
      searchMetadata(e.page.skip, e.page.take, sort, filterParamsArray, filter, userInfo);
    } else {
      searchMetadata(e.page.skip, e.page.take, sort, null, null, userInfo);
    }
  };

  const buildExportFilename = () => {
    let dateTime = moment().tz('America/Chicago').format('YYYYMMDD hh.mm.ss A');
    let fileName = 'Time run- ' + dateTime + ' Central';
    return fileName;
  };

  Object.flatten = function (data) {
    var result = {};
    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for (var i = 0, l = cur.length; i < l; i++) {
          result[prop] = {};
          break;
        }
        if (l == 0) result[prop] = [];
      } else {
        var isEmpty = true;
        for (var p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + '.' + p : p);
        }
        if (isEmpty && prop) result[prop] = {};
      }
    }

    recurse(data, '');
    return result;
  };

  // check if an element exists in array using a comparer function
  // comparer : function(currentElement)
  Array.prototype.inArray = function (comparer) {
    for (var i = 0; i < this.length; i++) {
      if (comparer(this[i])) return true;
    }
    return false;
  };

  // adds an element to the array if it does not already exist using a comparer
  // function
  Array.prototype.pushIfNotExist = function (element, comparer) {
    if (!this.inArray(comparer)) {
      this.push(element);
    }
  };

  // const exportExcel = () => {
  //   _export.save(
  //     records.metadata.map((itm) => itm.key),
  //     columnsToShow
  //   );
  // };

  const closePopup = () => {
    setDetailOpen(false);
    //setPageNumber(1);
    setObjectMetadata(null);
  };

  const closeUpdateObjectTransferModal = () => {
    setDisplayUpdateObjectTransferModal(false);
    setBulkPopupOpen(false);
  };

  const toggleUpdateObjectTransferModal = () => {
    setDisplayUpdateObjectTransferModal(!displayUpdateObjectTransferModal);
    setBulkPopupOpen(true);
  };

  //TODO need to push select column into records[]
  //TODO need to handle keyboard selection events for checkbox and view

  const handleNavigationAction = (event) => {
    const { focusElement } = event;
    const data = records;

    const rowId = focusElement.parentElement.getAttribute('data-id');

    if (rowId) {
      data.metadata.map((item) => ({
        ...item,
        selected: String(item._id) === rowId,
      }));
      //this.setState({ data: newData });
      setRecords(data);
    }
  };

  const handleRowNav = (props) => {
    const data = records;
    data.metadata.map((item) => ({
      ...item,
      selected: item._id === props.dataItem._id,
    }));
    //this.setState({ data: newData });
    setRecords(data);
  };

  const rowRender = (trElement, rowProps) => {
    const selectedRow = rowProps.dataItem.custom_id === clickedSearchRow;
    const newClassName = selectedRow === true ? 'highlightSearchTableRow' : '';
    const trProps = {
      ...trElement.props,
      className: newClassName,
      ['data-id']: rowProps.dataItem._id,
    };
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  const toggleUpdateObjFileCodePopup = (popupState) => {
    setLoadingFileCodes(true);
    setObjFileCodePopupOpen(popupState);
  };
  const handleDeleteObj = () => {
    onDeleteObject(rowsChecked);
  };

  let grid = (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ marginRight: 'auto' }}>
          <span className="d-flex align-items-center mb-2">
            <b>Search Results</b>
            {searchParamsObjArray.length > 0 && (
              <>
                <CustomButton title="Clear All" className={'ml-2'} onClick={clearAll} />
              </>
            )}
          </span>
          {rowsChecked.length > 0 ? (
            <>
              {userPermissions['Update_File_Code'] === true && (
                <a href="javascript:void(0)" className={'search-update-file-code'} onClick={() => toggleUpdateObjFileCodePopup(true)} title="Click to update File Code(s)">
                  Update File Code
                </a>
              )}

              {userPermissions['Update_Hold'] === true && (
                <a href="javascript:void(0)" className={'search-update-file-code'} onClick={openHoldModal} title="Click to update Hold(s)">
                  Update Hold
                </a>
              )}

              {userPermissions['Update_Transfer'] === true && (
                <a href="javascript:void(0)" className={'search-update-file-code'} onClick={toggleUpdateObjectTransferModal} title="Click to update Transfer(s)">
                  Update Transfer
                </a>
              )}
              {userPermissions['Delete_Folder'] === true && (
                <a href="javascript:void(0)" className={'search-update-file-code'} onClick={handleDeleteObj} title="Click to delete Object(s)">
                  Delete Folder
                </a>
              )}
            </>
          ) : null}
        </div>
        <div>
          {userPermissions['Export_Results'] === true && (
            <DownloadTableExcel filename={buildExportFilename()} sheet="Search" currentTableRef={TableRef.current}>
              <img
                style={{
                  height: '30px',
                  marginBottom: '.5em',
                  cursor: 'pointer',
                }}
                src="/img/excel.svg"
                alt="excel icon"
              ></img>
            </DownloadTableExcel>
          )}
        </div>
      </div>

      <div className={[styles.searchTable, 'searchTable'].join(' ')}>
        <Grid
          selectedField={SELECTED_FIELD}
          onNavigationAction={handleNavigationAction}
          rowRender={rowRender}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: 'multiple',
          }}
          sortable={false}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
            searchMetadata(skip, take, e.sort, null, null, userInfo);
          }}
          // style={{ maxHeight: '600px' }}
          skip={skip}
          take={take}
          data={records !== undefined ? records.metadata : []}
          total={records !== undefined ? records.total_hits : 0}
          pageable={{
            buttonCount: 5,
            info: true,
            type: 'numeric',
            pageSizes: [5, 10, 15, 20, 25],
            previousNext: true,
          }}
          onPageChange={pageChange}
          resizable={true}
          serverPaging={true}
          onRowClick={handleRowNav}
          onSelectionChange={handleRowClick}
        >
          {columnsToShow}
          <GridNoRecords>{errorMessage}</GridNoRecords>
        </Grid>
      </div>
    </div>
  );

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  return (
    <div className="searchGrid">
      {drmsSearch === true ? (
        <div>
          {isLoading === true && loadingPanel}
          <div>
            {/* <ExcelExport ref={(exporter) => (_export = exporter)} fileName={buildExportFilename()} data={records.metadata}>
              {grid}
            </ExcelExport> */}

            {grid}
          </div>
        </div>
      ) : (
        <div> {loadingPanel} </div>
      )}

      <UpdateObjectFileCodeModal
        loadingFileCodes={loadingFileCodes}
        setLoadingFileCodes={setLoadingFileCodes}
        objFileCodePopupOpen={objFileCodePopupOpen}
        setRecords={setRecords}
        records={records}
        toggleUpdateObjFileCodePopup={toggleUpdateObjFileCodePopup}
        ids={rowsChecked}
      />
      <UpdateObjectHoldModal onClose={closeHoldModal} visible={isHoldModalVisible} recordIds={rowsChecked} />
      {displayUpdateObjectTransferModal && (
        <UpdateObjectTransferModal visible={displayUpdateObjectTransferModal} onClose={closeUpdateObjectTransferModal} recordIds={rowsChecked} />
      )}
      {/* <section> */}
      <table style={{ display: 'none' }} ref={TableRef}>
        <tbody>
          <tr>
            {colData.length !== 0 &&
              colData.map((itm, idx) => {
                if (itm.title !== 'Select') {
                  if (itm.title !== '') {
                    return <th key={idx + '_select_col'}>{itm.title}</th>;
                  }
                }
              })}
          </tr>

          {records?.metadata?.length !== 0 &&
            records?.metadata?.map((rowItm) => {
              let arr = [];
              arr = Object.keys(rowItm?.key)?.map((keyItm, idx) => {
                let finalArr = [];
                finalArr = colData.map((itm, idx) => {
                  if (`key.${keyItm}` === itm?.field) {
                    return rowItm?.key[keyItm];
                  } else return '';
                });
                return finalArr;
              });

              return (
                <tr>
                  {arr?.length !== 0 &&
                    arr?.map((tItmArr, idx) => {
                      return (
                        tItmArr?.length !== 0 &&
                        tItmArr?.map((tItm) => {
                          if (tItm !== '') {
                            return <td key={idx + 'td_item'}>{tItm}</td>;
                          }
                        })
                      );
                    })}
                </tr>
              );
            })}
        </tbody>
      </table>

      {/* </section> */}
      <DetailsView
        isMetadataLoading={isMetadataLoading}
        cellClicked={cellClicked}
        popupOpen={detailOpen}
        closePopup={closePopup}
        objectID={objectID}
        skip={skip}
        take={take}
        sort={sort}
        doc={doc}
        searchMetadata={searchMetadata}
        objectMetadata={objectMetadata}
        setObjectMetadata={setObjectMetadata}
        loading={loading}
        onDocumentLoadSuccess={onDocumentLoadSuccess}
        pageNumber={pageNumber}
        changePage={changePage}
        numPages={numPages}
        docName={docName}
        docType={docType}
        scimsCustomers={scimsCustomers}
        metadataDisplayList={metadataDisplayList}
        setMetadataDisplayList={setMetadataDisplayList}
        setRecords={setRecords}
        records={records}
        userId={userId}
        agencyCode={agencyCode}
        docDetailResponse={docDetailResponse}
        documentTitle={documentTitle}
        sections={sections}
        detailsObjList={detailsObjList}
        selectObjectTypeToView={selectObjectTypeToView}
        objectTypeToView={objectTypeToView}
        selectedRecord={selectedRecord}
        errorMessage={errorMessage}
        holdResponse={holdResponse}
        refreshData={refreshData}
        fetchDocDetails={fetchDocDetails}
        deleteRecordEntry={deleteRecordEntry}
        dataUpdated={dataUpdated}
        setDataUpdated={setDataUpdated}
        fullTextFlag={fullTextFlag}
        keywordSearch={keywordSearch}
        setDropdownFilterChanged={setDropdownFilterChanged}
        fetchDropDownFilterCell={fetchDropDownFilterCell}
        setDropdownFilterCell={setDropdownFilterCell}
        dropdownFilterCell={dropdownFilterCell}
        dropdownFilterChanged={dropdownFilterChanged}
      />
    </div>
  );
}
