import axios from 'axios';
import { serviceUserJwt } from '../../../services/serviceConfig';

export async function searchDeleteObjectLog(searchObject, page, take, userInfo = undefined) {
  // let workQueueFilterResponse = [];
  // try {
  //   let url = process.env.REACT_APP_BACKEND_URL + '/smc_security_api/role/';
  //   if (data.text !== '' && data.column === '' && data.order === '') {
  //     url += 'search?keywords=' + data.text;
  //   } else {
  //     url += 'search?keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
  //   }

  //   await axios
  //     .get(url, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: serviceUserJwt,
  //       },
  //     })
  //     .then((response) => {
  //       workQueueFilterResponse = response;
  //     });
  // } catch (ex) {
  //   console.log('Error in get messages ', ex.message);
  // }
  // return workQueueFilterResponse;

  let searchResponse = [];

  try {
    const url = '/doc_and_metadata_submission_api/submission/search?page=' + page + '&limit=' + take;
    await axios
      .post(url, searchObject, {
        // TODO remove hardcoded values and get from userInfo
        headers: {
          officeId: 1,
          agencyCode: 'FA',
          Authorization: sessionStorage.getItem('userJwt'),
        },
      })
      .then((response) => {
        searchResponse = response;
      });
    return searchResponse;
  } catch (ex) {
    console.log('Error in metadataSubmissionService.js search()', ex.message);
    return ex;
  }
}
