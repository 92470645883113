import React, { useState, useEffect } from 'react';
import '../../../components/FileCode/FileCode.scss';
import DeleteObjectLogData from './DeleteObjectLogData.json';
import TableDeleteObjectLog from './DeleteObjectLogTable.js';
import { searchDeleteObjectLog } from './DeleteObjectLogServices.js';
import styles from './DeleteObjectLog.module.scss';
import '../../../GlobalStyle.scss';

import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import { Modal } from 'react-bootstrap';
import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
import AsyncSelect from 'react-select/async';
import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import Footer from '../../../newcomponents/Footer/Footer';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
import { handleIsRequiredError, handleMaxLimitError, resetErrorMessage } from '../../../utils/helper/handleReuiredErrorFunc';
import { createSearchObject } from '../../../components/search/CreateSearchObject.js';
import moment from 'moment';

var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Delete Object Log', link: '' },
];

export default function DeleteObjectLog() {
  const [data, setData] = useState([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [createEditModalShow, setCreateEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedDeleteRowData, setSelectedDeleteRowData] = useState();
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [deleteObjectLogDetails, setDeleteObjectLogData] = useState(DeleteObjectLogData);
  const glbContextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;
  const [totalHits, setTotalHits] = useState(0);
  const [formData, setFormData] = useState({
    contextId: glbContextId,
    Group: '',
    ObjectType: '',
    Section: '',
    Folder: '',
    employee_number: '',
    employmentstatus: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    statusreason: '',
    statusstartdate: '',
    term: '',
    user_name: '',
    modification_date: '',
  });
  //   async function fetchDeleteObjectLogData() {
  //     let payload = {
  //       text: searchText,
  //       column: sorting.column,
  //       order: sorting.order,
  //     };

  //     await searchDeleteObjectLog(payload).then((response) => {
  //       setData(response.data);
  //       setPage(0);
  //       setLoading(false);
  //     });
  //   }

  const fetchDeleteObjectLogData = (skip, take, sort, paramsArray = undefined, latestFilter = undefined, userInfo = undefined) => {
    let sorting = [];
    setLoading(true);
    if (sort !== undefined && sort !== null) {
      sorting.push(sort);
    } else {
      sorting.push({
        fieldName: 'doc_modification_history.modification_date',
        order: 'DESC',
        dataType: 'DATE',
      });
    }
    const metadataSearchObject = {
      searchCriteria: [
        {
          condition: 'EQUALS',
          field1: {
            name: 'common.is_deleted',
            value: [true],
          },
        },
      ],
      sort: sorting,
    };

    if (searchText && searchText.length !== 0) {
      let x = metadataSearchObject.searchCriteria[0];
      x.field2 = {
        value: searchText,
      };
    }

    return searchDeleteObjectLog(metadataSearchObject, skip, take, userInfo).then(async (result) => {
      if (result.isAxiosError === true) {
        setLoading(false);

        if (result.response.status === 404) {
          setLoading(false);
          setTotalHits(null);
          setData([]);
          //   setSearchErrorMessage('No records were found matching your search criteria.');
        } else {
          setLoading(false);
          setTotalHits(null);
          setData([]);
          //   setSearchErrorMessage("We're sorry, something went wrong, please try your search again.");
        }
      } else {
        setLoading(false);
        let newData = await result.data?.metadata?.map((obj, i) => {
          const objectSpecific = obj.object_specific;
          const docModification = obj.doc_modification_history?.find((mod) => mod.change_log === 'DELETED_OBJECT'); // Assuming doc_modification is available
          return {
            ...objectSpecific, // Spread the object_specific properties
            ...docModification, // Spread the doc_modification properties
            custom_id: 'index_' + i,
          };
        });
        setTotalHits(result.data.total_hits);
        setData(newData);
        // setData(result.data?.metadata?.object_specific);
        // sessionStorage.setItem('searchData', JSON.stringify(newData));

        // sessionStorage('searchData',JSON.stringify(result.data))
        // reorderColumn(result.data, latestFilter);
        // setRefreshColumn(true);
        // setIsLoading(false);
        // setIsSearchMetadataLoading(false);
      }
    });
  };

  useEffect(() => {
    setLoading(false);
    setPage(0);
    fetchDeleteObjectLogData(page, pageSize);
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);

    setSorting({ column: '', order: '' });

    // await searchDeleteObjectLog(payload).then((response) => {
    //   setData(response.data);
    //   setPage(0);
    //   setLoading(false);
    // });
    let payload = null;
    // await searchDeleteObjectLog(payload).then((response) => {
    //   setData(response.data);
    //   setLoading(false);
    // });

    fetchDeleteObjectLogData(page, pageSize, payload);
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    let pageSizePayload = page.take;
    setPage(page.skip / page.take);

    let pagePayload = page.skip / page.take;
    let payload;
    if (Object.keys(sorting).some((key) => sorting[key].trim() !== '')) {
      if (sorting.column === 'modification_date') {
        payload = {
          fieldName: `doc_modification_history.${sorting.column}`,
          order: sorting.order,
          dataType: 'DATE',
        };
      } else if (sorting.column === 'user_name') {
        payload = {
          fieldName: `doc_modification_history.${sorting.column}`,
          order: sorting.order,
          dataType: 'KEYWORD',
        };
      } else if (sorting.column === 'statusstartdate') {
        payload = {
          fieldName: `object_specific.${sorting.column}`,
          order: sorting.order,
          dataType: 'DATE',
        };
      } else {
        payload = {
          fieldName: `object_specific.${sorting.column}`,
          order: sorting.order,
        };
      }
    } else {
      payload = null;
    }
    fetchDeleteObjectLogData(pagePayload, pageSizePayload, payload);
  };

  const EditRowDataFunc = (data) => {
    setFormData({
      contextId: data.contextId || glbContextId,
      Group: data.Group,
      ObjectType: data.ObjectType,
      Section: data.Section,
      Folder: data.Folder,
      employee_number: data.employee_number,
      employmentstatus: data.employmentstatus,
      first_name: data.first_name,
      last_name: data.last_name,
      middle_name: data.middle_name,
      statusreason: data.statusreason,
      statusstartdate: moment(data.statusstartdate).format('MM/DD/YYYY'),
      term: data.term === true ? 'Yes' : 'No',
      user_name: data.user_name,
      modification_date: moment.utc(data.modification_date).local().format('MM-DD-YYYY hh:mm:ss A'),
    });

    setIsNewRecord(false);
    setCreateEditModalShow(true);
  };

  const DeleteRowDataFunc = (DeleteRowData) => {
    setDeleteModalShow(true);
    setSelectedDeleteRowData(DeleteRowData);
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (searchText) => {
    setPage(0);
    setLoading(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };
    fetchDeleteObjectLogData(page, pageSize);
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newDeleteObjectLogData = JSON.parse(JSON.stringify(deleteObjectLogDetails));
    newDeleteObjectLogData.forEach((itm) => {
      itm.sortType = '';
    });

    setDeleteObjectLogData(newDeleteObjectLogData);
    await fetchNewDeleteObjectLogData();
  };

  const fetchNewDeleteObjectLogData = async () => {
    // let payload = {
    //   text: '',
    //   column: '',
    //   order: '',
    // };
    const payload = null;
    // await searchDeleteObjectLog(payload).then((response) => {
    //   setData(response.data);
    //   setPage(0);
    //   setLoading(false);
    // });
    await fetchDeleteObjectLogData(page, pageSize, payload);
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    // {
    //     "fieldName": "object_specific.Folder",
    //     "order": "ASC"
    // }
    let payload;
    if (selectedColumn.field === 'modification_date') {
      payload = {
        fieldName: `doc_modification_history.${selectedColumn.field}`,
        order: selectedColumn.sortType,
        dataType: 'DATE',
      };
    } else if (selectedColumn.field === 'user_name') {
      payload = {
        fieldName: `doc_modification_history.${selectedColumn.field}`,
        order: selectedColumn.sortType,
        dataType: 'KEYWORD',
      };
    } else if (selectedColumn.field === 'statusstartdate') {
      payload = {
        fieldName: `object_specific.${selectedColumn.field}`,
        order: selectedColumn.sortType,
        dataType: 'DATE',
      };
    } else {
      payload = {
        fieldName: `object_specific.${selectedColumn.field}`,
        order: selectedColumn.sortType,
      };
    }

    // await searchDeleteObjectLog(payload).then((response) => {
    //   setData(response.data);
    //   setLoading(false);
    // });
    fetchDeleteObjectLogData(page, pageSize, payload);
  };

  return (
    <div className={styles.deleteObjectLogPage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.deleteObjectLogContent}>
        <h1 className={styles.pageTitle}>Delete Object Log</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent searchText={searchText} handleSearch={handleSearch} onSearch={onSearch} clearSearch={clearSearch}></SearchAdminPanelComponent>
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>

        <div className={[styles.deleteObjectLog, 'glbDeleteObjectLog'].join(' ')}>
          <TableDeleteObjectLog
            data={data}
            EditRowDataFunc={EditRowDataFunc}
            DeleteRowDataFunc={DeleteRowDataFunc}
            setDeleteModalShow={setDeleteModalShow}
            fields={deleteObjectLogDetails}
            page={page} // Pass the page state from the parent component
            pageSize={pageSize} // Pass the pageSize state from the parent component
            onPageChange={onPageChange}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
            totalHits={totalHits}
          />
        </div>

        <CreateEditDeleteObjectLogModal
          show={createEditModalShow}
          isNewRecord={isNewRecord}
          formData={formData}
          setFormData={setFormData}
          fetchDeleteObjectLogData={fetchDeleteObjectLogData}
          setCreateEditModalShow={setCreateEditModalShow}
          onHide={() => setCreateEditModalShow(false)}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
        />

        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

const CreateEditDeleteObjectLogModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={[styles.deleteObjectLogModal, 'glbDeleteObjectLogModalStyle'].join(' ')}
        onHide={props.onHide}
      >
        <div>
          <Modal.Header>
            <div className={styles.modalHeader}>
              <div>
                <h2 className={styles.modalHeaderTitle}>{props.isNewRecord ? 'Create Role' : 'View Delete Object Log'}</h2>
              </div>
              <div
                className={styles.modalHeaderIcon}
                onClick={() => {
                  props.setCreateEditModalShow(false);
                }}
              >
                <img src={crossIcon} alt="cross icon" />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className={styles.deleteObjectLogModalBody}>
            <div className={[styles.modalBodySection, 'row mr-0 ml-0'].join(' ')}>
              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label>Context Id</label>
                <input name="contextId" value={props.formData.contextId} type="text" disabled={true} />
              </div>

              <div className={[styles.inputFieldSection, props.isNewRecord ? 'col-12' : 'col-6'].join(' ')}>
                <label>Group</label>
                <input name="Group" value={props.formData.Group} type="text" disabled={true} />
              </div>
              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label>Object Type</label>
                <input name="ObjectType" value={props.formData?.ObjectType} disabled={true} type="text" />
              </div>

              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label>Section </label>
                <input name="Section" value={props.formData?.Section} disabled={true} type="text" />
              </div>
              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label>Folder </label>
                <input name="Folder" value={props.formData?.Folder} disabled={true} type="text" />
              </div>
              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label>Employee Number </label>
                <input name="employee_number" value={props.formData?.employee_number} disabled={true} type="text" />
              </div>
              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label>Employment Status </label>
                <input name="employmentstatus" value={props.formData?.employmentstatus} disabled={true} type="text" />
              </div>
              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label>Name First </label>
                <input name="first_name" value={props.formData?.first_name} disabled={true} type="text" />
              </div>
              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label>Name Last </label>
                <input name="last_name" value={props.formData?.last_name} disabled={true} type="text" />
              </div>
              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label>Name Middle </label>
                <input name="middle_name" value={props.formData?.middle_name} disabled={true} type="text" />
              </div>
              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label>Status Reason </label>
                <input name="statusreason" value={props.formData?.statusreason} disabled={true} type="text" />
              </div>
              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label>Status Start Date</label>
                <input name="statusstartdate" value={props.formData?.statusstartdate} disabled={true} type="text" />
              </div>
              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label>Term</label>
                <input name="term" value={props.formData?.term} disabled={true} type="text" />
              </div>
              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label>Deleted By</label>
                <input name="user_name" value={props.formData?.user_name} disabled={true} type="text" />
              </div>
              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label>Deleted On </label>
                <input name="modification_date" value={props.formData?.modification_date} disabled={true} type="text" />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className={styles.deleteObjectLogFooter}></Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
